<template>
  <div>
    <WaBtn />
    <!-- Main Banner -->
    <MainBanner class="" />

    <div class="h-10 bg-gray-200 lg:h-28"></div>
    <!-- Our Services -->
    <div class="py-10 bg-gray-200">
      <div class="container relative">
        <!-- one stop solution -->
        <div class="absolute inset-0 w-11/12 h-auto mx-auto -top-32">
          <div class="py-5 rounded-md shadow bg-nov-brown">
            <h1
              class="text-lg font-semibold text-center text-white md:text-2xl"
            >
              Professional Services Provider
            </h1>

            <div class="flex flex-wrap mt-6">
              <div
                v-for="(item, i) in one_stop"
                :key="i"
                class="w-1/2 mb-4 text-center md:w-1/3"
              >
                <img :src="item.image" :alt="item.alt" class="w-16 mx-auto" />
                <h1 class="mt-2 font-medium text-white">{{ item.alt }}</h1>
              </div>
            </div>
          </div>
        </div>
        <div class="h-72 md:h-48"></div>
      </div>
    </div>
    <div class="pb-10 bg-gray-200">
      <div class="container">
        <div class="inline-flex items-center">
          <div class="w-12 h-1 bg-nov-brown"></div>
          <h1 class="pl-2 text-5xl font-semibold text-red-900">Noventa</h1>
        </div>
        <h1 class="text-4xl font-bold text-gray-700">Our Services</h1>
        <div class="lg:inline-flex lg:items-center">
          <div class="mt-10 lg:w-1/2 lg:pr-4 md:flex md:flex-wrap">
            <!-- <div v-for="(item,i) in services" :key="i" class="w-1/2 p-1">
            <div class="rounded shadow-md bg-nov-gray">
            <img :src="item.image" :alt="item.alt" class="w-full h-auto rounded-t">
            <h1 class="px-4 py-4 text-2xl font-bold text-left text-white">{{item.alt}}</h1>
            </div>
          </div> -->
            <div
              v-for="(item, i) in services"
              :key="i"
              class="mb-2 md:p-2 md:w-1/2 lg:w-full"
            >
              <div class="flex items-start">
                <img :src="item.image" :alt="item.alt" class="pr-2 w-14" />
                <div class="pt-2">
                  <h1 class="text-xl font-bold text-nov-brown">
                    {{ item.alt }}
                  </h1>
                  <p class="mt-4 text-sm text-gray-600">{{ item.p }}</p>
                </div>
              </div>
            </div>
          </div>
          <img
            src="/images/bg-services.jpg"
            alt=""
            class="hidden w-1/2 mx-auto rounded shadow-md xl:w-1/3 lg:block"
          />
        </div>

        <div class="mt-5">
          <h1
            class="py-2 mx-auto text-center text-white lg:ml-4 lg:mx-0 bg-nov-gray w-52"
          >
            <i class="pr-1 fa-brands fa-whatsapp"></i>
            <a href="https://wa.me/60126936323"> Free Consultation </a>
          </h1>
        </div>
      </div>
    </div>
    <!-- end our services -->

    <div class="py-10 bg-nov-brown">
      <div class="container">
        <div class="flex items-center lg:max-w-3xl lg:mx-auto">
          <div class="w-1/3">
            <img src="/images/ce-logo.png" alt="" class="w-32 mx-auto" />
          </div>
          <div class="w-2/3 pl-5">
            <h1 class="text-lg leading-tight text-white">
              All Our Products are tested in compliance with European Standard
              EN13561 and EN1932
            </h1>
          </div>
        </div>
      </div>
    </div>

    <!-- Our Products -->
    <div class="pt-10">
      <div class="container">
        <div class="inline-flex items-center">
          <div class="w-12 h-1 bg-nov-brown"></div>
          <h1 class="pl-2 text-5xl font-semibold text-red-900">Noventa</h1>
        </div>
        <h1 class="text-4xl font-bold text-gray-700">Our Products</h1>

        <div class="mt-6">
          <h1 class="text-xl font-semibold text-nov-brown">
            Retractable Awning
          </h1>
          <div
            id="tabs"
            class="mt-2 space-y-1 md: md:space-y-0 md:flex md:flex-wrap lg:justify-center"
          >
            <div
              v-for="(item, i) in product_tabs"
              :key="i"
              class="w-full md:w-1/2 lg:w-1/4"
            >
              <div
                v-bind:class="[tab === item.tab ? 'active' : '']"
                @click="tab = item.tab"
                class="transition duration-300 ease-linear bg-gray-100 cursor-pointer hover:text-white hover:bg-nov-gray"
              >
                <h1 class="py-1 text-center">{{ item.menu }}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <div v-for="(item, i) in modal" :key="i" class="">
          <div v-show="tab == item.tab" class="flex flex-wrap">
            <div
              v-for="image in item.image_product"
              :key="image"
              class="w-1/2 md:w-1/4"
            >
              <img :src="image" alt="" class="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- roller binds -->
    <!-- <div class="mt-6">
      <div class="container">
        <div class="pr-2 lg:w-1/2">
          <h1 class="text-xl font-semibold text-nov-brown">Roller Blinds</h1>
        </div>
      </div>
      
      <div class="flex flex-wrap mt-8">
        <div v-for="image in roller_binds" :key="image" class="w-1/2 md:w-1/4">
          <img :src="image" :alt="image" class="" />
        </div>
      </div>
    </div> -->
    <div class="mt-6">
      <RollerBind />
    </div>

    <!-- Tensile Membrane -->
    <div class="pb-10 mt-6">
      <div class="container">
        <div class="md:w-1/2 md:order-2 md:pl-2">
          <h1 class="text-xl font-semibold text-nov-brown">Tensile Membrane</h1>
        </div>
      </div>
      <!-- carousel -->
      <div class="flex flex-wrap mt-8">
        <div v-for="image in tensile" :key="image" class="w-1/2 md:w-1/4">
          <img :src="image" :alt="image" class="" />
        </div>
      </div>
    </div>

    <div class="pb-10">
      <div class="flex flex-wrap">
        <div v-for="(item,i) in type" :key="i" class="w-1/2 md:w-1/4">
          <h1 class="py-2 font-bold text-center">{{item.alt}}</h1>
          <img :src="item.image" :alt="item.alt" class="" />
        </div>
      </div>
      <div class="mt-5">
        <h1 class="py-2 mx-auto text-center text-white bg-nov-gray w-52">
          <i class="pr-1 fa-brands fa-whatsapp"></i>
          <a href="https://wa.me/60126936323"> Free Consultation </a>
        </h1>
      </div>
    </div>

    <!-- why choose us -->
    <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-choose.jpg')"
    >
      <div class="container">
        <div class="inline-flex items-center">
          <div class="w-12 h-1 bg-white"></div>
          <h1 class="pl-2 text-4xl font-semibold text-white">Noventa</h1>
        </div>
        <h1 class="text-4xl font-bold text-nov-brown">Why Choose Us</h1>

        <div class="flex flex-wrap mt-8 md:justify-center lg:mx-auto">
          <div
            v-for="(item, i) in choose_us"
            :key="i"
            class="w-1/2 p-2 text-center md:w-1/3 lg:w-1/5"
          >
            <img :src="item.image" :alt="item.alt" class="w-20 mx-auto" />
            <h1 class="mt-4 text-sm leading-tight text-white">
              {{ item.alt }}
            </h1>
          </div>
        </div>
      </div>
    </div>

    <!-- About us -->
    <div class="py-10">
      <div class="container">
        <div class="lg:flex lg:items-center">
          <div class="lg:w-1/2 lg:pr-4">
            <div class="inline-flex items-center">
              <div class="w-12 h-1 bg-nov-brown"></div>
              <h1 class="pl-2 text-4xl font-semibold text-nov-blue">
                About Us
              </h1>
            </div>
            <h1 class="text-4xl font-bold text-red-900">Noventa</h1>
            <div class="mt-5 text-sm text-gray-600">
              <p>
                We already served you since 1976, and we were specializing in
                trading Canvas, P. E. Sheets, PVC, Nylon Canvas, Cotton Canvas
                and Acrylic Canvas, and also producing relevant product for all
                aspects. We are innovating all the times, and now, we are ready
                to serve you with great range of retractable awning products. On
                the other hand, we are also providing outdoor canvas products
                like Canopies, Marquees, Umbrellas, Tents etc. to serve
                beautifying and practical covering purpose for all well-known
                restaurants, government projects or functions, architectural
                design, and bungalows. Our goal is simple, we bring shades to
                the place without shades. We offer consulting services, design,
                fabrication, and installation to meet our customers’ need. We
                have skilful employees with more than 20 years experiences,
                support from our European suppliers on technology and
                development, and finally knowledge sharing from qualified
                engineers and architects to assist us in fulfilling request from
                our precious customers.
              </p>
            </div>
          </div>
          <div class="mt-3 lg:w-1/2 lg:mt-0">
            <img
              src="/images/about-us.jpg"
              alt=""
              class="shadow-md md:w-2/3 lg:w-full md:mx-auto"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="pt-10 bg-gray-200 lg:pt-0">
      <div class="lg:flex lg:items-center">
        <!-- left side -->
        <div class="px-4 lg:w-1/2 lg:order-2">
          <div class="inline-flex items-center">
            <div class="w-12 h-1 bg-nov-brown"></div>
            <h1 class="pl-2 text-4xl font-semibold text-red-900">Noventa</h1>
          </div>
          <h1 class="text-4xl font-bold text-gray-700">Among Our Designers</h1>

          <h1 class="mt-4 text-xl">Arch. Robby Cantarutti</h1>
          <p class="mt-4 text-sm text-gray-600">
            Professionally active since 1995, he immediately focuses his
            attention on seating design, working both as a designer and as a
            consultant for companies. Together with his wife Francesca
            Petricich, at the same time he deals with both Architecture and
            Interior Design, designing shops, private homes and exhibition
            stands. For BAT he designed the DOMEA model.
          </p>
        </div>
        <!-- right side -->
        <div class="mt-6 lg:mt-0 lg:w-1/2">
          <img
            src="/images/designer-1.jpg"
            alt=""
            class="mx-auto md:w-2/3 lg:w-full"
          />
        </div>
      </div>
    </div>

    <!-- enquire form -->
    <div class="py-10">
      <div id="element" class="container">
        <div class="inline-flex items-center">
          <div class="w-12 h-1 bg-nov-brown"></div>
          <h1 class="pl-2 text-4xl font-semibold text-red-900">Noventa</h1>
        </div>
        <h1 class="text-4xl font-bold text-gray-700">Enquire Us Now</h1>

        <div class="mt-8">
          <EnquiryForm class="lg:max-w-3xl lg:mx-auto" />
        </div>
      </div>
    </div>

    <!-- footer -->
    <div class="pt-10 bg-gray-50">
      <div class="container mb-5">
        <div class="text-center">
          <img src="/images/main-logo.png" alt="" class="w-56 mx-auto" />
          <h1 class="text-base font-semibold leading-tight text-gray-600">
            Lot 3, Lorong Perusahaan 2, Kimpal Industrial Park, 68100 Batu
            Caves, Selangor
          </h1>
          <div class="mt-4">
            <h1
              class="text-base font-semibold text-gray-600 transition duration-300 ease-linear hover:text-nov-brown"
            >
              <a href="tel:+60126936323"
                ><i class="fa-solid fa-phone"></i> 012-6936323</a
              >
            </h1>
            <h1
              class="text-base font-semibold text-gray-600 transition duration-300 ease-linear hover:text-nov-brown"
            >
              <a href="https://wa.me/60126936323"
                ><i class="pr-1 fa-brands fa-whatsapp"></i> Free Consultation</a
              >
            </h1>
          </div>
        </div>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.463825237489!2d101.68065051476432!3d3.234143253526164!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc470b39054a01%3A0x5089fcfd2b656839!2sNoventa%20Marketing%20(M)%20Sdn.%20Bhd.!5e0!3m2!1sen!2smy!4v1658221174224!5m2!1sen!2smy"
        width="100%"
        height="250"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>

    <!-- Copyright -->
    <div class="py-3 bg-nov-gray">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-white lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import MainBanner from "@/components/MainBanner.vue";
import carousel from "vue-owl-carousel";
import EnquiryForm from "@/components/EnquiryForm.vue";
import WaBtn from "@/components/WaBtn.vue";
import RollerBind from "@/components/RollerBind.vue";

export default {
  name: "Home",
  components: {
    MainBanner,
    carousel,
    EnquiryForm,
    WaBtn,
    RollerBind,
  },
  data() {
    return {
      tab: "tab-1",
      one_stop: [
        { image: "/images/icon-1.png", alt: "Consultation" },
        { image: "/images/icon-2.png", alt: "Recommendation" },
        { image: "/images/icon-3.png", alt: "Design" },
        { image: "/images/icon-4.png", alt: "Production" },
        { image: "/images/icon-5.png", alt: "Installation" },
        { image: "/images/icon-6.png", alt: "Success" },
      ],
      services: [
        {
          image: "/images/service-1.png",
          alt: "We Do",
          p: "Through our professional consultation services, we understand your needs & problems and always come up with possible solutions with excellent product.",
        },
        {
          image: "/images/service-2.png",
          alt: "New Design",
          p: "We will provide an optimized design by understanding your sun and rain problem and actual site situation and customize the shading with excellent design.",
        },
        {
          image: "/images/service-3.png",
          alt: "Product Recommendation",
          p: "We have a long variety of retractable systems & fixed structure, and we can recommend a product not just by problem solving, but we can provide product with different wind resistance & product quality to meet your requirements.",
        },
        {
          image: "/images/service-4.png",
          alt: "Replacement & Enforcement",
          p: "We provide service to study existing roofing problems & make improvement by making replacement or enforcement to provide a solution best benefit our customer. ",
        },
        {
          image: "/images/service-5.png",
          alt: "Supply of Awning System",
          p: "Need to replace some parts of your awning? Say the frame, faded fabric, awning fabric, or other components? You don't have to look far because we carry a supply of awning systems, including the parts that you may be looking for.",
        },
      ],
      product_tabs: [
        { tab: "tab-1", menu: "Retractable Arm Awning" },
        { tab: "tab-2", menu: "Giotto" },
        { tab: "tab-3", menu: "Gennius" },
        { tab: "tab-4", menu: "Cassette Awning" },
      ],
      modal: [
        {
          tab: "tab-1",
          image_product: [
            "/images/helix-5.jpg",
            "/images/helix-2.jpg",
            "/images/helix-1.jpg",
            // '/images/helix-4.jpg',
            "/images/helix-3.jpg",
          ],
        },
        {
          tab: "tab-2",
          image_product: [
            "/images/giotto-1.jpg",
            "/images/giotto-2.jpg",
            "/images/giotto-3.jpg",
            "/images/giotto-4.jpg",
          ],
        },
        {
          tab: "tab-3",
          image_product: [
            "/images/gennius-1.jpg",
            "/images/gennius-2.jpg",
            "/images/gennius-3.jpg",
            "/images/gennius-4.jpg",
          ],
        },
        {
          tab: "tab-4",
          image_product: [
            "/images/patio-1.jpg",
            "/images/patio-2.jpg",
            "/images/patio-3.jpg",
            "/images/patio-4.jpg",
          ],
        },
      ],
      roller_binds: [
        "/images/roller-1.jpg",
        "/images/roller-2.jpg",
        "/images/roller-3.jpg",
        "/images/roller-4.jpg",
      ],
      tensile: [
        "/images/tensile-1.jpg",
        "/images/tensile-2.jpg",
        "/images/tensile-3.jpg",
        "/images/tensile-4.jpg",
      ],
      choose_us: [
        { image: "/images/choose-1.png", alt: "One Stop Services Provided" },
        {
          image: "/images/choose-5.png",
          alt: "Free Consultation",
        },
        {
          image: "/images/choose-2.png",
          alt: " Site Visit Available",
        },
        {
          image: "/images/choose-3.png",
          alt: "Professional Consultant and Technician",
        },
        {
          image: "/images/choose-4.png",
          alt: "European CE Certificate Products (High Quality Products)",
        },
      ],
      type:[
        { image:'/images/other-4.jpg', alt:'Glass Awning'},
        { image:'/images/other-1.jpg', alt:'A-shape Tent'},
        { image:'/images/other-2.jpg', alt:'Pyramid Tent'},
        { image:'/images/other-3.jpg', alt:'Arabian Tent'},
      ]
    };
  },
};
</script>

<style>
#tabs .active {
  background-color: #272727;
  color: #fff;
}
</style>
