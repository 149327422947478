<template>
  <div class="bg-nov-gray lg:flex lg:items-center">
    <div class="w-full lg:w-2/3 lg:order-2">
      <hooper :settings="hooperSettings" style="height: 100%">
        <slide v-for="image in slides" :key="image" class="">
          <img :src="image" alt="" class="md:w-full" />
        </slide>
        <hooper-pagination slot="hooper-addons"></hooper-pagination>
      </hooper>
    </div>
    <div class="lg:w-1/3">
      <div class="flex items-center pt-5 space-x-2 bg-gray-400 lg:pt-3">
        <div class="">
          <img src="/images/main-logo.png" alt="" class="w-32 mx-auto 2xl:w-60 " />
        </div>
        <div>
          <img src="/images/ce-logo-black.png" alt="" class="w-20 h-auto 2xl:w-32" />
        </div>
      </div>
      <div class="px-4 pt-10 pb-20 lg:pt-5 ">
        <div class="text-white">
          <h1 class="text-lg font-medium md:text-2xl lg:text-lg 2xl:text-3xl">
            We Provide Different Type of
          </h1>
          <h1 class="text-4xl font-bold md:text-6xl lg:text-2xl xl:text-4xl 2xl:text-5xl text-nov-brown">
            Retractable Awning Systems and Permanent Structures
          </h1>
          <h1 class="text-lg font-medium md:text-2xl lg:text-lg 2xl:text-3xl">
            Suit Your Ideas from Zero To Hero
          </h1>
        </div>
        <div
          v-if="!hideEnquiry"
          class="mt-6 space-y-1 text-center text-white md:space-x-1 lg:space-x-0 md:space-y-0 md:flex lg:space-y-1 lg:block"
        >
          <div class="">
            <h1
              class="py-2 transition duration-300 ease-linear border w-52 hover:bg-white hover:text-gray-900"
            >
              <a href="https://wa.me/60126936323"
                ><i class="pr-1 fa-brands fa-whatsapp"></i> Free Consultation</a
              >
            </h1>
          </div>
          <div class="">
            <h1
              class="py-2 transition duration-300 ease-in-out border w-52 bg-nov-brown border-nov-brown hover:bg-white hover:text-gray-900"
            >
              <a href="tel:+60126936323">Call Us 012-6936323</a>
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Hooper, Slide, Pagination as HooperPagination } from "hooper";
import "hooper/dist/hooper.css";
export default {
  components: {
    Hooper,
    Slide,
    HooperPagination,
  },
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hooperSettings: {
        itemsToShow: 1,
        infiniteScroll: true,
        wheelControl: false,
        autoPlay: true,
        playSpeed: 4500,
      },
      slides: [
        "/images/banner-1.jpg",
        "/images/banner-2.jpg",
        "/images/banner-3.jpg",
        "/images/banner-4.jpg",
      ],
    };
  },
};
</script>

<style></style>
