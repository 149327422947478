<template>
  <div>
    <div class="container">
      <div class="mt-6">
        <h1 class="text-xl font-semibold text-nov-brown">Roller Blinds</h1>
        <div
          id="tabs"
          class="mt-2 space-y-1 md: md:space-y-0 md:flex md:flex-wrap lg:justify-start"
        >
          <div
            v-for="(item, i) in roller_tabs"
            :key="i"
            class="w-full md:w-1/2 lg:w-1/4"
          >
            <div
              v-bind:class="[tab === item.tab ? 'active' : '']"
              @click="tab = item.tab"
              class="transition duration-300 ease-linear bg-gray-100 cursor-pointer hover:text-white hover:bg-nov-gray"
            >
              <h1 class="py-1 text-center">{{ item.menu }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5">
      <div v-for="(item, i) in roller_modal" :key="i" class="">
        <div v-show="tab == item.tab" class="flex flex-wrap">
          <div
            v-for="image in item.image_product"
            :key="image"
            class="w-1/2 md:w-1/4"
          >
            <img :src="image" alt="" class="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab: "tab-1",
      roller_tabs: [
        { tab: "tab-1", menu: "Cabrio" },
        { tab: "tab-2", menu: "Zip Blind" },
      ],
      roller_modal: [
        {
          tab: "tab-1",
          image_product: [
           '/images/roller-1.jpg',
           '/images/roller-2.jpg',
           '/images/roller-3.jpg',
           '/images/roller-4.jpg',
          ],
        },
        {
          tab: "tab-2",
          image_product: [
            "/images/roller-5.jpg",
            "/images/roller-6.jpg",
            "/images/roller-7.jpg",
            "/images/roller-8.jpg",
          ],
        },
      ],
    };
  },
};
</script>

<style>
</style>